const header = document.querySelector("header");
const sectionOne = document.querySelector(".first-section");

const sectionOneOptions = {
    rootMargin: "-400px 0px 0px 0px"
};

const sectionOneObserver = new IntersectionObserver(function(
        entries,
        sectionOneObserver
    ) {
        entries.forEach(entry => {
            if (!entry.isIntersecting) {
                header.classList.add("nav-bg-on");
            } else {
                header.classList.remove("nav-bg-on");
            }
        });
    },
    sectionOneOptions);

sectionOneObserver.observe(sectionOne);